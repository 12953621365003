.Logo {
    font-size: 40px;  
    font-family:Pacifico-Regular;
    /* font-family:Fab-Felt; */
    letter-spacing: 10;
    color: white;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
    text-decoration: none;
}
